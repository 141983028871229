// components
import { AnnouncementPreview } from '../components/AnnouncementPreview';
import { CalendarEventPreview } from '../components/CalendarEventPreview';

import img1 from '../assets/img1.jpg';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.JPG';
import img4 from '../assets/img4.JPG';

export function Home(data) {
  return (
    <>
      <div className="home-preview-container">
        <div className="home-content-box announcements-box">
          <div className="content-box-title">Announcements</div>
          <div className="content-box-title-divider"></div>
          {data.announcements &&
            data.announcements.slice(0, 3).map((announcement) => {
              return (
                <>
                  <AnnouncementPreview
                    message={announcement.message}
                    datePosted={announcement.datePosted}
                  ></AnnouncementPreview>
                  <div className="event-divider"></div>
                </>
              );
            })}
        </div>
        <div className="home-content-box events-box">
          <div className="content-box-title">Upcoming Events</div>
          <div className="content-box-title-divider"></div>
          {data.previewEvents &&
            data.previewEvents.map((event) => {
              return (
                <>
                  <CalendarEventPreview
                    title={event.title}
                    date={event.date}
                    time={event.time}
                  ></CalendarEventPreview>
                  <div className="event-divider"></div>
                </>
              );
            })}
        </div>
      </div>
      <div className="home-content-box about-box">
        <div className="content-box-title">About Our Club</div>
        <div className="content-box-title-divider"></div>
        <div className="content-card">
          <img className="content-img" src={img1}></img>
          <div className="content-text">
            Dresden Lawn Bowling Club started back over one hundred and fifteen
            years ago, in 1907. In that time the club has produced some of the
            finest bowlers in all of Ontario and all of Canada.
          </div>
        </div>
        <div className="content-card">
          <div className="content-text">
            Two of our members have been inducted into the Dresden Sports Hall
            of Fame. Muriel McKim for her many accomplishments and support of
            Lawn Bowls for over 50 years and Erin Roth for her many
            accomplishments since the age of 12, playing all over the world in
            the junior program and formerly on the Canadian National Team with
            one of the highlights competing at the Commonwealth games in New
            Delhi, India in 2010.
          </div>
          <img className="content-img" src={img2}></img>
        </div>
        <div className="content-card">
          <img className="content-img-style-2" src={img3}></img>
          <div className="content-text">
            The Club currently has approximately 20 members. Club members gather
            two times per week for in-club competitions as well as have numerous
            open club tournaments.
          </div>
        </div>
        <div className="content-card">
          <div className="content-text">
            In the past four years we have had Ontario Provincial Champions for
            Ladies Singles, Ladies Pairs, Men's Novice (under 5 years'
            experience), Men's Fours (Finished 4th at Nationals) and Women's
            Pairs winning Silver in the Canadian Nationals in Winnipeg.
          </div>
          <img className="content-img-style-2" src={img4}></img>
        </div>
      </div>
    </>
  );
}
