import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faGithub,
  faLinkedin,
  faPinterest,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

export function Contact() {
  return (
    <div>
      <div className="contact-section-header">Club</div>
      <div className="contact-section">
        <div className="contact-club">Email: dresdenlawnbowls@hotmail.com</div>
      </div>
      <div className="contact-section-header">People</div>
      <div className="contact-section">
        <div className="contact-person">Deb Aarssen: +1 (519) 384-3470</div>
        <div className="contact-person">Annette Dhaen: +1 (519) 401-4014</div>
        <div className="contact-person">Jeff Wilbur: +1 (519) 365-0744</div>
        <div className="contact-person">Chris Ripley: +1 (519) 365-0450</div>
      </div>
      <div className="contact-section-header">Socials</div>
      <div className="contact-section">
        <a
          className="external-href"
          href="https://www.facebook.com/DresdenLawnBowlingClub"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={faFacebookSquare}
            className="fa-2xl icon"
          ></FontAwesomeIcon>
          Facebook
        </a>
      </div>
      <div className="contact-section-header">Find Us</div>
      <div className="contact-section">
        <a
          className="external-href"
          href="https://www.google.com/maps/place/Kinsmen+Community+Park/@42.587801,-82.176518,18z/data=!4m5!3m4!1s0x0:0xe38082f2c843647f!8m2!3d42.5876606!4d-82.1770178?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Kinsmen Community Park, Sydenham St, Dresden, On
        </a>
      </div>
    </div>
  );
}
