import { useLocation } from 'react-router-dom';

import { Home } from '../Pages/Home';
import { Contact } from '../Pages/Contact';
import { Calendar } from '../Pages/Calendar';
import { About } from '../Pages/About';
import { Sponsors } from '../Pages/Sponsors';

export function Page(data) {
  const location = useLocation();

  return (
    <div className="page">
      {location.pathname === '/' ? (
        <Home {...data}></Home>
      ) : location.pathname === '/contact' ? (
        <Contact></Contact>
      ) : location.pathname === '/calendar' ? (
        <Calendar {...data}></Calendar>
      ) : location.pathname === '/sponsors' ? (
        <Sponsors></Sponsors>
      ) : location.pathname === '/about/' ? (
        <About></About>
      ) : (
        <Home></Home>
      )}
    </div>
  );
}
