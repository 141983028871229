// hooks
import { useEffect, useState } from 'react';
import axios from 'axios';

// containers
import { Nav } from './containers/Nav';
import { Page } from './containers/Page';
import { Footer } from './containers/Footer';

// styles
import './styles/css/main.css';

// page definitions
const HOME = 'home';
const NEWS = 'news';
const CALENDAR = 'calendar';
const ABOUT = 'about';
const SPONSORS = 'sponsors';

const monthsByName = {
  'January': 0,
  'February': 1,
  'March': 2,
  'April': 3,
  'May': 4,
  'June': 5,
  'July': 6,
  'August': 7,
  'September': 8,
  'October': 9,
  'November': 10,
  'December': 11
};

const monthsByNumber = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const baseURL = 'https://opensheet.elk.sh/1mwbmoo-hs-EDK7SY4OevkvBEjMTOj6p-Y4BcNjNdmOU';
const eventsEndpoint = '/events';
const announcementsEndpoint = '/announcements';

async function getData() {
  let eventsData = null;
  let announcementsData = null;


  try {
    eventsData = await axios.get('https://opensheet.elk.sh/1mwbmoo-hs-EDK7SY4OevkvBEjMTOj6p-Y4BcNjNdmOU/events');
  } catch (error) {
    console.log(error);
  }
  try {
    announcementsData = await axios.get('https://opensheet.elk.sh/1mwbmoo-hs-EDK7SY4OevkvBEjMTOj6p-Y4BcNjNdmOU/announcements');
  } catch (error) {
    console.log(error);
  }

  console.log(eventsData.data);

  const dateObj = new Date();
  const currentMonth = dateObj.getMonth();
  const currentDate = dateObj.getDate();

  const previewEvents = [];

  let count = 0;
  eventsData.data.forEach((event) => {
    if (count < 3 && new Date(event.date) > dateObj) {
      const eventDate = new Date(event.date);
      let formattedTime = null;
      if (event.time) {
        const dummyDateTime = new Date('1970-01-01T' + event.time + 'Z');
        let hours = dummyDateTime.getHours() + 5; // + 5 for EST
        const minutes = dummyDateTime.getMinutes();
        if (hours > 12) {
          // is PM
          hours -= 12;
          formattedTime = `${hours}:${minutes}PM`
        } else {
          formattedTime = `${hours}:${minutes}AM`
        }
      }
      
      previewEvents.push({
        title: event.title,
        date: `${monthsByNumber[eventDate.getMonth()]} ${eventDate.getDate() + 1}`,
        time: formattedTime
      });
      count++;
    }
    if (event.time) {
      event['allDay'] = false;
      event['start'] = `${event.date}T${event.time}`;
    }
  });

  // don't purge old announcements, dummy
  // announcementsData.data.forEach((announcement) => {
  //   const dateFragments = announcement.datePosted.split(' ');
  //   console.log(dateFragments);
  //   if (monthsByName[dateFragments[0]] < currentMonth || (monthsByName[dateFragments[0]] === currentMonth && dateFragments[1] < currentDate)) {
  //     announcementsData.data.shift();
  //   }
  // });

  const sortedAnnouncements = announcementsData.data.reverse();

  return {
    events: Object.values(eventsData.data),
    // announcements: Object.values(announcementsData.data),
    announcements: sortedAnnouncements,
    previewEvents
  };
}

function App() {

  const [data, setData] = useState(null);

  useEffect(() => {
    const foo = async () => {
      const response = await getData();
      console.log(response);
      setData(response);
    }
    foo();
  }, []);



  return (
    <div className="app">
      <Nav></Nav>
      <Page {...data}></Page>
    </div>
  );
}

export default App;
