// import { CalendarEvent } from '../components/CalendarEvent';
import { useState } from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

export function Calendar(data) {
  return (
    <div className='calendar-container'>
      <FullCalendar
          plugins={[ dayGridPlugin ]}
          initialView="dayGridMonth"
          events={
            data.events
          }
        />
    </div>
  )
}