import { Link, useLocation } from 'react-router-dom';

export function NavButton(props) {
  const { text, path } = props;
  const location = useLocation();

  return (
    <Link
      to={path}
      className={
        'nav-button' + (location.pathname === path ? ' nav-current' : '')
      }
    >
      {text}
    </Link>
  );
}
