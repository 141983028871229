export function Sponsors() {
  return (
    <div>
      Dresden Lawn Bowling Club would like to give a Special Thanks to all our
      Sponsors who's donations help maintain our club and keep our tournaments
      going. Without Your Support we wouldn't be able to carry on our rich
      history. Thank You.
    </div>
  );
}
