export function AnnouncementPreview(props) {

  const { message, datePosted } = props;

  return (
    <div className="announcement-preview">
      <div className="announcement-message">{message && message}</div>
      <div className="announcement-date-container">
        <div className="announcement-date">Posted: {datePosted}</div>
      </div>
    </div>
  );
}
