import { NavButton } from '../components/NavButtons';
// import logo from '../assets/logo-512x512.png';
import logo from '../assets/logo.png';

export function Nav(props) {
  const setCurrentPage = props.setCurrentPage;

  return (
    <div className="nav">
      <div className="logo-container">
        <img className={'nav-logo'} src={logo}></img>
        <h1 className="logo-title">Dresden Lawn Bowling Club</h1>
      </div>
      <div className="nav-button-group">
        <NavButton
          text={'Home'}
          path={'/'}
          setCurrentPage={setCurrentPage}
        ></NavButton>
        <NavButton
          text={'Calendar'}
          path={'/calendar'}
          setCurrentPage={setCurrentPage}
        ></NavButton>
        <NavButton
          text={'Contact Us'}
          path={'/contact'}
          setCurrentPage={setCurrentPage}
        ></NavButton>
        {/* <NavButton
          text={'Sponsors'}
          path={'/sponsors'}
          setCurrentPage={setCurrentPage}
        ></NavButton> */}
      </div>
    </div>
  );
}
