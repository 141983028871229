export function CalendarEventPreview(props) {
  const { title, description, date, time } = props;
  const dateFragments = date.split(' ');

  return (
    <div className="calendar-event-preview">
      <div className="date-box">
        <div>{dateFragments[0]}</div>
        <div>{dateFragments[1]}</div>
      </div>
      <div className="event-details-container">
        <div className="event-title">{title}</div>
        <div className="event-date-container">
          <div className="event-time">{time}</div>
        </div>
      </div>
    </div>
  );
}
